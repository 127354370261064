<template>
  <div class="slider-wrapper">
    <i v-if="collection.length > 7" class="before" />
    <div class="slider">
      <ul :style="`width:${collection.length * (20 + itemRect.width)}px;`">
        <li v-for="(item, i) in collection" :key="`slider-${item.id}-${i}`">
          <div :style="`width:${itemRect.width}px;height:${itemRect.height}px;`" class="poster">
            <template v-if="item.poster">
              <!-- <nuxt-img
                v-if="i === 0 || i === 1"
                :src="item.poster"
                :alt="item.name"
                preload
                fit="cover"
                loading="lazy"
                format="webp"
                width="300"
                height="170"
                quality="90"
                class="lazyload"
              /> -->
              <nuxt-img
                v-if="preload"
                :src="item.poster"
                :alt="item.name"
                fit="cover"
                loading="lazy"
                format="webp"
                width="300"
                height="170"
                quality="70"
                preload
              />
              <nuxt-img
                v-else
                :src="item.poster"
                :alt="item.name"
                fit="cover"
                loading="lazy"
                format="webp"
                width="300"
                height="170"
                quality="70"
              />
            </template>
            <template v-else>
              <img
                :data-src="item.background_image"
                :alt="'Постер ' + item.name"
                src="/static_files/no-poster.png"
                class="lazyload"
              >
            </template>
          </div>
          <strong :style="`width:${itemRect.width}px;`" class="name-ru">
            {{ item.name}}
          </strong>
          <nuxt-link :to="`/games/${item.id}-${item.slug}`" :title="item.name">
            {{ item.name }}
          </nuxt-link>
        </li>
        <!-- <li v-if="collection.length >= 7" class="collection-show-more">
          <i v-if="collection.length < items.length" class="collection-show-more-plus" @click="sliderArrows('right', $event)" />
          <i v-else class="collection-show-more-minus" @click="sliderArrows('left', $event)" />
        </li> -->
      </ul>
    </div>
    <div v-if="collection.length > 6" class="arrows">
      <i v-if="collection.length > 7" class="larrow" @click="sliderArrows('left', $event)" />
      <i class="rarrow" @click="sliderArrows('right', $event)" />
    </div>
    <i v-if="collection.length < 20" class="after" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: { items: { type: Array, required: true, default: () => [] }, preload: { type: Boolean, default: false, } },
  data() {
    return {
      collection: [],
    }
  },
  computed: {
    ...mapState(['isDesktop']),
    itemRect() {
      return  { width: 300, height: 170 } // this.isDesktop ? { width: 170, height: 240 } : { width: 170, height: 240 }
    }
  },
  fetch() {
    this.collection = this.items.slice(0, 10)
  },
  // mounted() {
  //   this.lazyLoadImgs()
  // },
  methods: {
    sliderArrows(side, event) {
      let elem = null
      if (event.target.parentElement.className === 'collection-show-more') {
        elem = event.target.parentElement.parentElement.parentElement
        if (side === 'left') {
          this.collection = this.items.slice(0, 10)
          elem.scrollTo(0, 0)
        } else if (side === 'right') {
          if (this.itemRect.width === 190) {
            this.collection = this.items.slice(0, this.collection.length + 5)
            this.$nextTick(() => elem.scrollTo(elem.scrollLeft + this.itemRect.width * 7, 0))
          } else {
            this.collection = this.items.slice(0, this.collection.length + 10)
          }
        }
      } else {
        elem = event.target.parentElement.previousElementSibling
        if (side === 'left') {
          if (this.collection.length >= 11)
            this.collection = this.items.slice(0, this.collection.length - 4)
          elem.scrollTo(elem.scrollLeft - 300 - this.itemRect.width * 3, 0)
        } else if (side === 'right') {
          if (this.collection.length < this.items.length)
            this.collection = this.items.slice(0, this.collection.length + 4)
          elem.scrollTo(elem.scrollLeft + 300 + this.itemRect.width * 3, 0)
        }
      }
      this.$nextTick(() => this.lazyLoadImgs())
    }
  }
}
</script>
